import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import 'mantine-react-table/styles.css'

import { MantineReactTable, MRT_TableOptions as TableOptions, useMantineReactTable } from 'mantine-react-table'
import { useEffect, useImperativeHandle } from 'react'
import { Pagination } from '../Pagination'
import { cn } from '@/utils-v2/shadcn-utils'
import { Box } from '@mantine/core'
import { parseFromValuesOrFunc } from './utils'

const PAGE_SIZE_OPTIONS = [5, 10, 20]

const MantineTable = <T,>({
  columns,
  data,
  tableRef,
  onClickRow,
  onRowSelected,
  ...tableOptions
}: {
  tableRef?: any
  onClickRow?: (row: T) => void
  onRowSelected?: (rows: T[]) => void
} & TableOptions<T>) => {
  const {
    enableGrouping = false,
    mantineSelectCheckboxProps,
    mantineSelectAllCheckboxProps,
    mantineTableBodyProps,
    mantineTableProps,
    mantineTableHeadCellProps,
    mantineTableBodyRowProps,
    mantineTableContainerProps,
    mantinePaperProps,
    displayColumnDefOptions,
    enablePagination = true,
    ...rest
  } = tableOptions

  const table = useMantineReactTable<T>({
    // default settings
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enablePagination,
    enableGrouping,
    paginationDisplayMode: 'pages',
    enableStickyHeader: true,
    initialState: { pagination: { pageSize: 10, pageIndex: 0 } },
    mantineTableHeadCellProps: (props) => ({
      p: '10px 16px',
      fz: 12,
      fw: 500,
      c: 'gray.6',
      bg: 'gray.0',
      bd: 'none',
      style: {
        letterSpacing: '0.05em'
      },
      ...parseFromValuesOrFunc(mantineTableHeadCellProps, props)
    }),
    mantinePaperProps: (props) => ({
      shadow: 'none',
      ...parseFromValuesOrFunc(mantinePaperProps, props)
    }),
    mantineTableContainerProps: (props) => ({
      className: cn([(mantineTableContainerProps as any)?.className, !data?.length && '!overflow-hidden']),
      ...parseFromValuesOrFunc(mantineTableContainerProps, props)
    }),
    mantineTableBodyProps: (props) => ({
      h: !data?.length && '100%',
      ...parseFromValuesOrFunc(mantineTableBodyProps, props)
    }),
    mantineTableBodyRowProps: (props) => {
      const { row } = props
      const isGroupedRow = row.getIsGrouped()

      return {
        onClick: (e) => {
          if (onClickRow && !isGroupedRow) onClickRow(row.original)
        },
        className: cn(['hover:!bg-grey-100', onClickRow && !isGroupedRow && 'cursor-pointer']),
        ...parseFromValuesOrFunc(mantineTableBodyRowProps, props)
      }
    },
    mantineTableProps: (props) => ({
      h: !data?.length && '100%',
      ...parseFromValuesOrFunc(mantineTableProps, props)
    }),
    mantineSelectCheckboxProps: (props) => ({
      size: 'sm',
      ...parseFromValuesOrFunc(mantineSelectCheckboxProps, props)
    }),
    mantineSelectAllCheckboxProps: (props) => ({
      size: 'sm',
      ...parseFromValuesOrFunc(mantineSelectAllCheckboxProps, props)
    }),
    displayColumnDefOptions: enableGrouping
      ? {
          'mrt-row-expand': {
            mantineTableBodyCellProps: {
              display: 'none'
            },
            mantineTableHeadCellProps: {
              display: 'none'
            }
          },
          ...displayColumnDefOptions
        }
      : displayColumnDefOptions,
    // -------------------

    ...rest,
    columns,
    data
  })

  const selectedRows = table.getSelectedRowModel()

  useEffect(() => {
    if (onRowSelected) {
      onRowSelected(selectedRows.flatRows.map((_row) => _row.original))
    }
  }, [selectedRows])

  useImperativeHandle(tableRef, () => table, [table])

  return (
    <>
      <MantineReactTable table={table} />
      {data?.length > 0 && enablePagination && <PaginationSection table={table} />}
    </>
  )
}

export default MantineTable

const PaginationSection = ({ table }) => {
  const { pagination, isLoading } = table.getState()

  if (isLoading) return null

  const pageCount = table.getPageCount()
  const canNextPage = table.getCanNextPage()
  const canPreviousPage = table.getCanPreviousPage()

  return (
    <Box style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 20 }} mt={16}>
      <Pagination
        currentPage={pagination.pageIndex}
        totalPages={pageCount === 0 ? 1 : pageCount}
        currentPageSize={pagination.pageSize}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onChangePageSize={(e) => table.setPageSize(Number(e.target.value))}
        onPageChange={(pageNum) => table.setPageIndex(pageNum - 1)}
        onClickPreviousPage={table.previousPage}
        onClickNextPage={table.nextPage}
        onClickFirstPage={() => table.setPageIndex(0)}
        onClickLastPage={() => table.setPageIndex(pageCount - 1)}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
      />
    </Box>
  )
}
