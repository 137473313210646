import { createSlice } from '@reduxjs/toolkit'
import { organizationMembersApi } from './organization-members.api'
import { IOrganizationMember } from './organization-members.types'

interface IOrganizationMembersSliceState {
  members: IOrganizationMember[] | []
  profile: any
}

const initialState: IOrganizationMembersSliceState = {
  members: [],
  profile: null
}

export const organizationMembersSlice = createSlice({
  name: 'organization-members-slice',
  initialState,
  reducers: undefined,
  extraReducers: (builder) => {
    builder
      .addMatcher(organizationMembersApi.endpoints.getMembers.matchFulfilled, (state, { payload }) => {
        state.members = payload?.data?.items
      })
      .addMatcher(organizationMembersApi.endpoints.getAuthenticatedProfile.matchFulfilled, (state, { payload }) => {
        state.profile = payload?.data
      })
  }
})
