import { useDeleteWalletGroupMutation } from '@/api/wallet-group-api'
import ChainFilters from '@/components-v2/molecules/ChainFilters/ChainFilters'
import { showBannerSelector } from '@/slice/platform/platform-slice'
import { useAppSelector } from '@/state'
import { log } from '@/utils-v2/logger'
import { useOrganizationId } from '@/utils/getOrganizationId'
import { Input, Stack, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { IconSearch } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import CreateGroupModal from '../CreateGroupModal/CreateGroupModal'
import WalletGroupTable from './WalletGroupTable'

interface IWalletGroup {
  data: any
  loading: boolean
  supportedChains: any[]
  groupChainsFilter: string[]
  areAllChainsSelected: boolean
  onChangeFilter: (chain) => void
  onSelectAllChain: () => void
  search: string
  onChangeSearch: (search) => void
}

const WalletGroup: React.FC<IWalletGroup> = ({
  data,
  supportedChains,
  groupChainsFilter,
  onChangeFilter,
  onSelectAllChain,
  search,
  onChangeSearch
}) => {
  const [deleteWalletGroup, deleteWalletGroupResult] = useDeleteWalletGroupMutation()

  const organizationId = useOrganizationId()
  const showBanner = useAppSelector(showBannerSelector)

  const [selectedGroup, setSelectedGroup] = useState<any>()
  const [showEdit, setShowEdit] = useState(false)

  useEffect(() => {
    if (!showEdit) {
      setSelectedGroup(null)
    }
  }, [showEdit])

  useEffect(() => {
    if (deleteWalletGroupResult.isSuccess) {
      notifications.show({ message: 'Successfully deleted wallet group.', variant: 'success' })
    }
    if (deleteWalletGroupResult.isError) {
      notifications.show({
        message:
          deleteWalletGroupResult.error.data.message || 'There was an issue deleting the wallet. Please try again.',
        variant: 'error'
      })
      log.error(
        `${deleteWalletGroupResult?.error?.status} API Error while deleting wallet group`,
        [`${deleteWalletGroupResult?.error?.status} API Error while deleting wallet group`],
        {
          actualErrorObject: deleteWalletGroupResult?.error
        },
        `${window.location.pathname}`
      )
    }
  }, [deleteWalletGroupResult])

  const onOpenEditModal = (row) => {
    setSelectedGroup(row)
    setShowEdit(true)
  }

  const onOpenDeleteModal = (row) => {
    modals.openContextModal({
      modal: 'confirm',
      title: (
        <Stack gap={4}>
          <p>Delete Wallet?</p>
          <Text size="md">
            You are deleting Group <b>{row.name}</b>. This action cannot be undone.
          </Text>
        </Stack>
      ),
      classNames: { header: '!pb-6 border-b', body: '!pt-6' },
      innerProps: {
        labels: { confirm: 'Yes, Delete', cancel: 'No, Don’t Delete' },
        onConfirm: () =>
          deleteWalletGroup({
            payload: {
              id: row.id
            },
            orgId: organizationId
          }).unwrap()
      }
    })
  }

  return (
    <div className="pt-6">
      <div className="flex items-center justify-center mb-3">
        <ChainFilters values={groupChainsFilter} onChange={onChangeFilter} onClear={onSelectAllChain} />
      </div>
      <Input
        w="20%"
        miw={250}
        value={search}
        leftSection={<IconSearch size={14} />}
        placeholder="Search by wallet group name"
        onChange={onChangeSearch}
      />
      <WalletGroupTable
        data={data}
        showBanner={showBanner}
        supportedChains={supportedChains}
        groupChainsFilter={groupChainsFilter}
        onEdit={onOpenEditModal}
        onDelete={onOpenDeleteModal}
      />

      <CreateGroupModal
        groups={data}
        setShowModal={setShowEdit}
        showModal={showEdit}
        selectedGroup={selectedGroup}
        action="Edit"
      />
    </div>
  )
}

export default WalletGroup
