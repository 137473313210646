import { wagmiConfig } from '@/shared/wagmi/config'
import { supportedChainsSelector } from '@/slice/chains/chains-slice'
import { useAppSelector } from '@/state'
import { capitalize } from 'lodash'
import { useMemo, useRef } from 'react'
import { defineChain, http } from 'viem'
import { createConfig } from 'wagmi'
import { injected, walletConnect } from 'wagmi/connectors'

const projectId = '3612a65e438ee547fbcdcb70f09f61fb'

type BlockExplorer = {
  name: string
  url: string
}
type NativeCurrency = {
  name: string
  symbol: string
  decimals: number
}

const parseChain = ({
  chainId,
  name,
  rpcUrl,
  nativeCurrency,
  blockExplorer,
  testnet = false
}: {
  chainId: number
  name: string
  rpcUrl: string
  nativeCurrency: NativeCurrency
  blockExplorer: BlockExplorer
  testnet?: boolean
}) =>
  defineChain({
    id: chainId,
    name,
    nativeCurrency,
    rpcUrls: {
      default: { http: [rpcUrl] }
    },
    blockExplorers: {
      default: blockExplorer
    },
    testnet,
    contracts: {}
  })

export const useWagmiConfig = () => {
  const _config = useRef(null)

  const chainDetails = useAppSelector(supportedChainsSelector)

  const customConfig = useMemo(() => {
    if (chainDetails?.length && !_config.current) {
      const chains: any = chainDetails.map((chain) =>
        parseChain({
          blockExplorer: {
            name: capitalize(chain.blockExplorer.split('/')[2].split('.')[0]),
            url: chain.blockExplorer
          },
          chainId: Number(chain.chainId),
          name: chain.name,
          nativeCurrency: { name: chain.token.name, symbol: chain.token.symbol, decimals: chain.token.decimal },
          rpcUrl: chain.rpcUrl,
          testnet: chain?.isTestnet
        })
      )

      const transports = {}

      chainDetails.forEach((chain) => {
        transports[Number(chain.chainId)] = http(chain.rpcUrl)
      })

      const config = createConfig({
        chains,
        connectors: [injected(), walletConnect({ projectId })],
        transports: { ...transports }
      })

      _config.current = config

      return config
    }

    return _config.current || wagmiConfig
  }, [chainDetails])

  return customConfig
}
