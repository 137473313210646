import Tooltip from '@/public/svg/Info.svg'
import { Combobox, Group, InputBase, Text, useCombobox, Tooltip as MantineTooltip } from '@mantine/core'
import { usePrevious } from '@mantine/hooks'
import { IconCheck } from '@tabler/icons-react'
import Image from 'next/image'
import { useEffect, useState } from 'react'

const GroupCell = ({ data, groupOptions, onChangeGroup }) => {
  const [search, setSearch] = useState('')
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  })

  const selectedOption = groupOptions?.find((option) => option.value === data?.group?.id)
  const previousSelectedOption = usePrevious(selectedOption)

  useEffect(() => {
    if (data?.group?.id === null) {
      setSearch('')
    }

    if (
      selectedOption &&
      (previousSelectedOption?.value !== selectedOption.value || previousSelectedOption?.label !== selectedOption.label)
    ) {
      setSearch(selectedOption.label)
    }
  }, [data?.group?.id, selectedOption])

  const shouldFilterOptions = groupOptions.every((item) => item.label !== search)
  const filteredOptions = shouldFilterOptions
    ? groupOptions.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
    : groupOptions

  const options = filteredOptions.map((item) => (
    <Combobox.Option
      bg={item.value === data?.group?.id ? 'gray.1' : 'white'}
      value={item.value}
      key={item.value}
      onClick={(e) => e.stopPropagation()}
    >
      <Group flex="1" gap="xs" fz={12}>
        {item.label}
        {item.value === data?.group?.id && <IconCheck style={{ marginInlineStart: 'auto' }} size={14} />}
      </Group>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        onChangeGroup(data, val)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          classNames={{ input: '!text-xs' }}
          onChange={(event) => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
            setSearch(event.currentTarget.value)
          }}
          onClick={(e) => {
            e.stopPropagation()
            combobox.openDropdown()
          }}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown()
            setSearch(data.group.name || '')
          }}
          placeholder="Search group"
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>No group found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default GroupCell

export const GroupHeader = () => (
  <Group gap={6}>
    <p>Wallet Group</p>
    <MantineTooltip
      label={
        <Text size="sm" fw={500} className="whitespace-normal">
          Use Wallet Groups to group together the cost basis of your assets
        </Text>
      }
    >
      <Image
        src={Tooltip}
        className="cursor-pointer"
        alt="info"
        width={14}
        height={14}
        data-for="header-wallet-group"
        data-tip="header-wallet-group"
      />
    </MantineTooltip>
  </Group>
)
