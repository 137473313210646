/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { getAccessToken } from '@/utils/localStorageService'

type RequestConfig = {
  method?: string
  headers?: Record<string, string>
  body?: any
  timeout?: number
}

interface CustomConfig extends Omit<RequestConfig, 'body'> {
  headers?: Record<string, string>
}

class APIError extends Error {
  response: { status: number; data: any }

  constructor(status: number, data: any) {
    super('API Error')
    this.response = { status, data }
  }
}


const createHeaders = () => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  }

  const accessToken = getAccessToken()
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return headers
}

type FetchError = Error & {
  name: string
  message: string
}

async function fetchWithTimeout(resource: string, options: RequestConfig = {}) {
  const { timeout = 30000 } = options // Default 30 second timeout
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), timeout)

  try {
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
      headers: {
        ...createHeaders(),
        ...(options.headers || {})
      }
    })
    clearTimeout(timeoutId)

    // Handle non-JSON responses
    const contentType = response.headers.get('content-type')
    const isJson = contentType && contentType.includes('application/json')

    if (!response.ok) {
      const errorData = isJson ? await response.json() : await response.text()
      throw new APIError(response.status, errorData)
    }

    if (isJson) {
      const data = await response.json()
      return { data, status: response.status }
    }

    const data = await response.text()
    return { data, status: response.status }
  } catch (error) {
    clearTimeout(timeoutId)
    if (error instanceof APIError) {
      throw error
    }
    const fetchError = error as FetchError
    if (fetchError.name === 'AbortError') {
      throw new APIError(408, { message: 'Request timeout' })
    }
    throw new APIError(500, { message: fetchError.message || 'Unknown error occurred' })
  }
}

const customFetch = async (url: string, config: RequestConfig = {}) => {
  const fullUrl = `${url}`

  const finalConfig = {
    ...config,
    headers: {
      ...createHeaders(),
      ...(config.headers || {})
    }
  }

  if (finalConfig.body && typeof finalConfig.body === 'object') {
    finalConfig.body = JSON.stringify(finalConfig.body)
  }

  return fetchWithTimeout(fullUrl, finalConfig)
}

// Expose the same API as before with proper types
const fetchConfig = {
  get: (url: string, config: CustomConfig = {}) => customFetch(url, { ...config, method: 'GET' }),
  post: (url: string, data: unknown, config: CustomConfig = {}) =>
    customFetch(url, {
      ...config,
      method: 'POST',
      body: data,
      headers: {
        ...(config.headers || {}),
        'Content-Type': 'application/json'
      }
    }),
  put: (url: string, data: unknown, config: CustomConfig = {}) =>
    customFetch(url, {
      ...config,
      method: 'PUT',
      body: data,
      headers: {
        ...(config.headers || {}),
        'Content-Type': 'application/json'
      }
    }),
  delete: (url: string, config: CustomConfig = {}) => customFetch(url, { ...config, method: 'DELETE' }),
  patch: (url: string, data: unknown, config: CustomConfig = {}) =>
    customFetch(url, {
      ...config,
      method: 'PATCH',
      body: data,
      headers: {
        ...(config.headers || {}),
        'Content-Type': 'application/json'
      }
    })
}

export default fetchConfig

type ApiResponseData = {
  message?: string
  [key: string]: unknown
}

export const getRemoteChartOfAccounts = async ({
  organizationId,
  integration
}: {
  organizationId: string
  integration: string
}) => {
  try {
    const response = await fetchConfig.get(
      `${process.env.NEXT_PUBLIC_API_URL}/${organizationId}/chart-of-accounts/pass-through/${integration}/import-new`,
      { timeout: 60000 }
    )
    return response.data
  } catch (error) {
    const err = error as APIError
    const responseData = err?.response?.data as ApiResponseData
    return {
      isError: true,
      ...(responseData || { message: err.message || 'Unknown error occurred' })
    }
  }
}
