/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
// SentryService.ts
import * as Sentry from '@sentry/nextjs'

export type BradcrumbTypes = 'default' | 'navigation' | 'http' | 'user' | 'debug' | 'error' | 'query' | 'ui' | 'info'
export type ErrorLevels = 'critical' | 'error' | 'warning' | 'info' | 'debug' | 'fatal' | 'log'
export type TagTypes = 'payments'

interface ICaptureMessage {
  message: string
  level: ErrorLevels
  extra: any
}

interface ICaptureExecption {
  title: string
  exception: Error
  level?: ErrorLevels
  extra: any
  tag: TagTypes
}

interface ISetBaseContext {
  userId: string
  userLoginCredential: string // Use username property
  organizationId: string
  organizationName: string
}

// Breadcrumb Types - https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
interface IAddBreadcrumb {
  type: BradcrumbTypes // Controls how breadcrumbs are categorized
  category: string // Helps to understand what the user was doing when the breadcrumb was captured
  message: string // Data to send with the event
  level?: ErrorLevels
  data?: any
  timestamp: Date
}

const severityMap: Record<ErrorLevels, Sentry.Severity> = {
  critical: Sentry.Severity.Critical,
  error: Sentry.Severity.Error,
  warning: Sentry.Severity.Warning,
  info: Sentry.Severity.Info,
  debug: Sentry.Severity.Debug,
  fatal: Sentry.Severity.Fatal,
  log: Sentry.Severity.Log
}

class SentryService {


  public setBaseContext({ userId, userLoginCredential, organizationId, organizationName }: ISetBaseContext): void {
    Sentry.setUser({ id: userId, username: userLoginCredential })
    Sentry.setTag('organizationId', organizationId)
    Sentry.setTag('organizationName', organizationName)
  }

  public clearBaseContext(): void {
    Sentry.setUser(null)
    Sentry.setTag('organizationId', undefined)
  }

  public captureMessage({ message, extra, level }: ICaptureMessage): void {
    Sentry.captureMessage(message, {
      level: level as Sentry.Severity,
      ...(extra && { extra })
    })
  }

  public captureException({ exception, level = Sentry.Severity.Critical, title, extra, tag }: ICaptureExecption): void {
    // const error = exception instanceof Error ? exception : new Error(String(exception))
    Sentry.captureException(exception, (scope) => {
      scope.setTag('feature', tag)
      scope.setLevel(severityMap[level])
      scope.setTransactionName(title)
      scope.setContext('Payload', extra)

      return scope
    })
  }

  public addBreadcrumb({ category, message, level = Sentry.Severity.Info, data, type }: IAddBreadcrumb): void {
    Sentry.addBreadcrumb({
      type,
      category,
      message,
      data,
      level: level as Sentry.Severity
    })
  }
}

export default new SentryService()
