import { createSelector } from '@reduxjs/toolkit'
import { IOrganizationMember } from './organization-members.types'
// @ts-ignore
const selectSelf = (state: AppState) => state.organizationMembers

export const selectMembersMapByAddress = createSelector(selectSelf, (state): Record<string, IOrganizationMember> => {
  const membersMap = state.members.reduce((acc, member) => {
    acc[member?.authName.toLowerCase()] = member

    return acc
  }, {} as Record<string, IOrganizationMember>)
  return membersMap
})
export const membersSelector = createSelector(selectSelf, (state) => state.members)
export const memberProfileSelector = createSelector(selectSelf, (state) => state.profile)
