import React, { useEffect, useRef } from 'react'
import { ActionIcon, Flex, NativeSelect } from '@mantine/core'
import { IconChevronLeft, IconChevronRight, IconChevronsRight, IconChevronsLeft } from '@tabler/icons-react'

export interface IPaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  onClickPreviousPage: () => void
  onClickNextPage: () => void
  onClickFirstPage: () => void
  onClickLastPage: () => void
  canPreviousPage: boolean
  canNextPage: boolean
  pageSizeOptions: number[]
  onChangePageSize: any
  currentPageSize: number
}

const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  onClickFirstPage,
  onClickLastPage,
  onClickNextPage,
  onClickPreviousPage,
  canNextPage,
  canPreviousPage,
  pageSizeOptions,
  onChangePageSize,
  currentPageSize
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      const characterWidth = 14 // Adjust this value based on your desired character width
      const inputWidth = Math.max(24, String(currentPage).length * characterWidth)
      inputRef.current.style.width = `${inputWidth + 14}px`
    }
  }, [currentPage])

  const helpChangePage = (page: string) => {
    onPageChange(Number(page))
  }

  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\D/, '') // Remove non-numeric characters

    if (+inputValue <= 1) helpChangePage(String(1))
    else if (+inputValue >= totalPages) helpChangePage(totalPages.toString())
    else helpChangePage(inputValue)
  }

  const handlePreviousPage = () => {
    onClickPreviousPage()
  }

  const handleNextPage = () => {
    onClickNextPage()
  }

  const handleFirstPage = () => {
    onClickFirstPage()
  }

  const handleLastPage = () => {
    onClickLastPage()
  }

  return (
    <Flex gap={8} align="center">
      <ActionIcon size="lg" onClick={handleFirstPage} disabled={!canPreviousPage} color="blue">
        <IconChevronsLeft />
      </ActionIcon>
      <ActionIcon size="lg" onClick={handlePreviousPage} disabled={!canPreviousPage} color="blue">
        <IconChevronLeft />
      </ActionIcon>
      <input
        ref={inputRef}
        className="px-2 py-1 text-center border rounded"
        type="number"
        pattern="[0-9]*"
        value={Number(currentPage) + 1}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
      />
      <span className="mx-2">of {totalPages}</span>
      <ActionIcon size="lg" onClick={handleNextPage} disabled={!canNextPage} color="blue">
        <IconChevronRight />
      </ActionIcon>
      <ActionIcon size="lg" onClick={handleLastPage} disabled={!canNextPage} color="blue">
        <IconChevronsRight />
      </ActionIcon>

      <NativeSelect value={String(currentPageSize)} onChange={onChangePageSize} w={120}>
        {pageSizeOptions.map((_size) => (
          <option key={_size} value={_size}>
            Show {_size}
          </option>
        ))}
      </NativeSelect>
    </Flex>
  )
}

export default Pagination
