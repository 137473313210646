import { AppState, RootState } from '@/state'
import { createSelector } from '@reduxjs/toolkit'

const selectSelf = (state: RootState) => state

// TODO - Change this to named arguments
export const selectFeatureState = (
  state: AppState,
  feature: string,
  userId?: string,
  organizationId?: string
): boolean => {
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT
  const featureData = state.featureFlag?.[feature] ?? false

  if (!featureData) return false

  if (userId && featureData.users && featureData.users.includes(userId)) {
    return true
  }

  if (organizationId && featureData.organizations && featureData.organizations.includes(organizationId)) {
    return true
  }

  if (featureData.env && featureData.env.includes(env)) {
    return true
  }

  return false
}

export const selectAllCryptoCurrencies = createSelector(selectSelf, (state) => {
  const x = state.featureFlag
  const allCryptoCurrencies = state.cryptocurrencies
  return []
})
