import React from 'react'
import { Tooltip } from '@mantine/core'
import styles from './chainlist.module.css'

export interface IChainItem {
  id: string
  name: string
  imageUrl: string
  isGrayedOut?: boolean
}

interface IListChains {
  chains: IChainItem[]
}

export const ChainList: React.FC<IListChains> = ({ chains }) => (
  <div className={styles.wrapper}>
    {chains?.length > 0 ? (
      chains?.map((chain, index) => (
        <div key={chain.id} className={styles.chainBlockParent}>
          <Tooltip label={chain?.name} position="top" withArrow>
            <div className={styles.chainBlock}>
              <img
                src={chain.imageUrl}
                alt="chain"
                className={`rounded-[4px] mr-1 ${chain.isGrayedOut ? 'grayscale' : ''}`}
                width={18}
                height={18}
              />
            </div>
          </Tooltip>
        </div>
      ))
    ) : (
      <div>-</div>
    )}
  </div>
)
export default ChainList
