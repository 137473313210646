import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { Store } from '@reduxjs/toolkit'
import React from 'react'
import { Provider } from 'react-redux'
import CustomWagmiProvider from './contexts/CustomWagmiProvider'
import contextModals, { modalDefaultProps } from './shared/modals-manager/modals'
import { mantineTheme } from './shared/theme/mantine.theme'

const isLocalHost = process.env.NEXT_PUBLIC_ENVIRONMENT === 'localhost'

const Providers: React.FC<{ children; store: Store; nonce }> = ({ children, store, nonce }) => (
  <Provider store={store}>
    <CustomWagmiProvider>
      <MantineProvider theme={mantineTheme} {...(!isLocalHost && { getStyleNonce: () => nonce })}>
        <Notifications position="top-right" zIndex={10000} />
        <ModalsProvider modalProps={modalDefaultProps} modals={contextModals}>
          {children}
        </ModalsProvider>
      </MantineProvider>
    </CustomWagmiProvider>
  </Provider>
)

export default Providers
