import { ICryptocurrencyItem } from '@/slice/wallets/wallet-types'
import { api } from './index'

interface IAssetsParams {
  orgId: string
  params?: {
    nameOrSymbol?: string
    blockchainIds?: any
    walletIds?: string[]
    cryptocurrencyIds?: string[]
  }
}

export interface IAsset {
  blockchainId: string
  cryptocurrency: ICryptocurrencyItem
  currentFiatPrice: string
  fiatCurrency: string
  totalCostBasis: string
  totalCurrentFiatValue: string
  totalUnits: string
}

export const assetsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAssets: builder.query<IAsset[], IAssetsParams>({
      query: ({ orgId, params }) => ({
        url: `${orgId}/assets`,
        method: 'GET',
        params
      }),
      transformResponse: (response) => response.data,
      providesTags: ['assets']
    }),
    getTaxLots: builder.query<any, any>({
      query: ({ orgId, params, publicId }) => ({
        url: `${orgId}/assets/${publicId}/tax-lots`,
        method: 'GET',
        params
      }),
      transformResponse: (response) => response.data,
      providesTags: ['assets']
    }),
    getAssetCryptocurrencies: builder.query<ICryptocurrencyItem[], any>({
      query: ({ orgId, params }) => ({
        url: `${orgId}/assets/cryptocurrencies`,
        method: 'GET',
        params
      }),
      transformResponse: (response) => response.data,
      providesTags: ['assets']
    })
  })
})

export const { useGetAssetsQuery, useGetTaxLotsQuery, useGetAssetCryptocurrenciesQuery } = assetsApi
