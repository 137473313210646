import { ActionIcon, Menu, Tooltip } from '@mantine/core'
import { IconDotsVertical } from '@tabler/icons-react'
import ReactTooltip from 'react-tooltip'

const ActionCell = ({ data, isWalletSyncing, isOwner }) => (
  <Menu shadow="md" width={120} position="bottom-end">
    <Menu.Target>
      <ActionIcon
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <IconDotsVertical size={20} />
      </ActionIcon>
    </Menu.Target>

    <Menu.Dropdown>
      <Menu.Item component="button" onClick={data.onEditButton}>
        Edit
      </Menu.Item>
      <Tooltip
        label={
          isWalletSyncing
            ? 'We are syncing transactions data. You will be able to delete a wallet after the sync is completed.'
            : 'Only owners can perform this action.'
        }
        disabled={!(isWalletSyncing || !isOwner)}
      >
        <Menu.Item
          component="button"
          disabled={isWalletSyncing || !isOwner}
          className="disabled:cursor-not-allowed !pointer-events-auto"
          onClick={data.onButtonClick}
          c="red"
        >
          Delete
        </Menu.Item>
      </Tooltip>
    </Menu.Dropdown>
  </Menu>
)

export default ActionCell
