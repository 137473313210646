import { useGetBalanceForWalletsGroupedByChainQuery } from '@/api/balances-api'
import { useGetWalletGroupsQuery } from '@/api/wallet-group-api'
import Button from '@/components-v2/atoms/Button'
import Typography from '@/components-v2/atoms/Typography'
import MantineTabs from '@/components-v2/MantineTabs/MantineTabs'
import SyncChip from '@/components-v2/molecules/SyncChip'
import { Header, AuthenticatedView as View } from '@/components-v2/templates/AuthenticatedView'
import Loading from '@/components/Loading'
import { isFeatureEnabledForThisEnv } from '@/config/constants'
import { useWalletSync } from '@/hooks-v2/useWalletSync'
import { useDebounce } from '@/hooks/useDebounce'
import editSvg from '@/public/svg/Edit.svg'
import deleteSvg from '@/public/svg/TrashRed.svg'
import flagSvg from '@/public/svg/warning.svg'
import { supportedChainsSelector } from '@/slice/chains/chains-slice'
import { orgSettingsSelector } from '@/slice/orgSettings/orgSettings-slice'
import { useDeleteWalletMutation, useGetWalletsQuery } from '@/slice/wallets/wallet-api'
import { IWalletParams, SourceType } from '@/slice/wallets/wallet-types'
import { useAppSelector } from '@/state'
import { log } from '@/utils-v2/logger'
import { formatNumberWithCommasBasedOnLocale } from '@/utils-v2/numToWord'
import { useOrganizationId } from '@/utils/getOrganizationId'
import { Stack, TabsPanel, Text, TextInput } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { uniqBy } from 'lodash'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'
import { IScoreRatingProps } from './components/AddWallet/types'
import CreateGroupModal from './components/CreateGroupModal/CreateGroupModal'
import WalletList from './components/WalleList/WalletList'
import WalletGroup from './components/WalletGroup/WalletGroup'
import { SelectWalletType } from './ImportWallet'
import { EWalletTab, walletTabs } from './types'

const LazyQRModal = dynamic(() => import('@/components-v2/molecules/QRModal/QRModal'), {
  ssr: false,
  loading: () => <div>Loading...</div>
})

const SourceOfFunds = () => {
  const router = useRouter()
  const { ...methods } = useForm<IWalletParams>({
    defaultValues: {
      assetIds: [],
      walletGroupIds: []
    }
  })

  const [filter, setFilter] = useState<IWalletParams>({
    assetIds: [],
    walletGroupIds: [],
    blockchainIds: []
  })

  const [groupChainsFilter, setGroupChainsFilter] = useState<string[]>([])
  const [direction, setDirection] = useState(true) // true is order by higher balance
  const [textSearch, setTextSearch] = useState('')
  const [textGroupSearch, setTextGroupSearch] = useState('')
  const { debouncedValue: search } = useDebounce(textSearch, 500)
  const { debouncedValue: groupSearch } = useDebounce(textGroupSearch, 500)

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: parseFloat(process.env.NEXT_PUBLIC_MAXIMUM_TRANSACTIONS_HISTORY) || 20
  })

  const [areAllWalletChainsSelected, setAreAllWalletChainsSelected] = useState<boolean>(isFeatureEnabledForThisEnv) // Default to true once deployed to all envs
  const [areAllGroupChainsSelected, setAreAllGroupChainsSelected] = useState<boolean>(isFeatureEnabledForThisEnv) // Default to true once deployed to all envs

  const organizationId = useOrganizationId()

  const supportedChains = useAppSelector(supportedChainsSelector)
  const isWalletSyncing = useAppSelector((state) => state.wallets.isSyncing)
  const { fiatCurrency: fiatCurrencySetting, country } = useAppSelector(orgSettingsSelector)
  const { startWalletSync, lastUpdated } = useWalletSync({
    organisationId: organizationId
  })

  const [showCreateGroup, setShowCreateGroup] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(EWalletTab.WALLETS)
  const [action, setAction] = useState('')
  const [isParsing, setIsParsing] = useState(true)

  const {
    data: groups,
    isLoading: groupsLoading,
    isError: isGetWalletGroupsError,
    error: getWalletGroupsError,
    isUninitialized: groupsUninitialized
  } = useGetWalletGroupsQuery(
    {
      orgId: organizationId
    },
    { skip: !organizationId }
  )

  const {
    data: sources,
    isLoading: walletsLoading,
    isFetching: isWalletFetching,
    isError: isWalletFetchError,
    error: walletFetchError,
    isUninitialized: walletsUninitialized,
    refetch
  } = useGetWalletsQuery(
    {
      orgId: organizationId,
      params: {
        size: pagination.pageSize,
        page: pagination.pageIndex,
        search,
        ...filter,
        includeCryptocurrencyMetadata: true
      }
    },
    { skip: !organizationId }
  )

  const { data: walletBalances, refetch: walletBalancesRefetch } = useGetBalanceForWalletsGroupedByChainQuery(
    {
      orgId: organizationId,
      params: { groupBy: 'walletId', secondGroupBy: 'blockchainId', blockchainIds: [...filter.blockchainIds] }
    },
    { skip: !organizationId }
  )

  const [deleteWallet, deleteWalletResult] = useDeleteWalletMutation()

  const parsedWalletGroup = useMemo(() => {
    if (groups) {
      // search
      const searchedGroup = groupSearch
        ? groups?.filter((group) => group.name.toLowerCase().includes(groupSearch.trim().toLowerCase()))
        : [...groups]

      // chain filter

      const filteredGroup = groupChainsFilter?.length
        ? searchedGroup.filter((group) => groupChainsFilter.some((chain) => group.supportedBlockchains.includes(chain)))
        : [...searchedGroup]

      return filteredGroup
    }
    return []
  }, [groupChainsFilter, groupSearch, groups])

  useEffect(() => {
    if (isWalletFetchError) {
      log.critical(
        walletFetchError?.message ?? 'Error while fetching wallets',
        ['Error while fetching wallets'],
        {
          actualErrorObject: walletFetchError.data,
          errorStatusCode: walletFetchError.status
        },
        `${window.location.pathname}`
      )
    }
  }, [isWalletFetchError])

  useEffect(() => {
    if (!isWalletSyncing) {
      refetch()
      walletBalancesRefetch()
    }
  }, [isWalletSyncing])

  useEffect(() => {
    if (isGetWalletGroupsError) {
      log.error(
        getWalletGroupsError?.message ?? 'Error while fetching wallet groups on wallets page',
        ['Error while fetching wallet groups on wallets page'],
        {
          actualErrorObject: getWalletGroupsError.data,
          errorStatusCode: getWalletGroupsError.status
        },
        `${window.location.pathname}`
      )
    }
  }, [isGetWalletGroupsError])

  useEffect(() => {
    if (deleteWalletResult.isSuccess) {
      notifications.show({ message: 'Successfully deleted wallet.', variant: 'success' })
    }
    if (deleteWalletResult.isError) {
      notifications.show({
        message: deleteWalletResult.error.data.message || 'There was an issue deleting the wallet. Please try again.',
        variant: 'error'
      })
      log.error(
        `${deleteWalletResult?.error?.status} API Error deleting wallet`,
        [` ${deleteWalletResult?.error?.status} API Error deleting wallet`],
        {
          actualErrorObject: deleteWalletResult?.error
        },
        `${window.location.pathname}`
      )
    }
  }, [deleteWalletResult])

  const countedWalletTabs = useMemo(
    () =>
      walletTabs.map((tab) => ({
        ...tab,
        count: tab.value === EWalletTab.WALLETS ? sources?.totalItems : groups?.length,
        className: 'lg:!hidden'
      })),
    [groups?.length, sources?.totalItems]
  )

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
    setTextSearch(e.target.value)
  }

  const handleChangeGroupSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
    setTextGroupSearch(e.target.value)
  }

  const handleChangeTab = (tab: string) => {
    setTextSearch('')
    setTextGroupSearch('')
    setPagination({
      pageIndex: 0,
      pageSize: parseFloat(process.env.NEXT_PUBLIC_MAXIMUM_TRANSACTIONS_HISTORY) || 20
    })
    setActiveTab(tab)
  }

  const handleShowDeleteModal = (fundId: string) => {
    const deleteWalletSource = sources.items.find((deleteItem) => deleteItem.id === fundId)

    modals.openContextModal({
      modal: 'confirm',
      title: (
        <Stack gap={4}>
          <p>Delete Wallet?</p>
          <Text c="dimmed" size="md">
            All transactions and assets on this wallet will also be deleted. You can import this wallet again later.
          </Text>
        </Stack>
      ),
      classNames: { header: '!pb-6 border-b', body: '!pt-6' },
      innerProps: {
        modalBodyProps: { pl: 0 },
        modalBody: (
          <Stack gap={32}>
            <TextInput label="Wallet Name" value={deleteWalletSource.name} disabled />
            <TextInput label="Wallet Address" value={deleteWalletSource.address} disabled />
          </Stack>
        ),
        labels: { confirm: 'Delete Wallet', cancel: 'Back' },
        onConfirm: () => deleteWallet({ orgId: organizationId, payload: { id: deleteWalletSource.id } }).unwrap()
      }
    })
  }

  const handleWalletChainFilter = (chainIdSelected: string) => {
    if (filter.blockchainIds.includes(chainIdSelected)) {
      setFilter({ ...filter, blockchainIds: filter.blockchainIds.filter((chain) => chain !== chainIdSelected) })
      if (filter.blockchainIds.filter((chain) => chain !== chainIdSelected).length === 0) {
        setAreAllWalletChainsSelected(true)
      }
    } else {
      setFilter({ ...filter, blockchainIds: [...filter.blockchainIds, chainIdSelected] })
      setAreAllWalletChainsSelected(false)
    }
  }

  const handleAllWalletChainSelect = () => {
    setAreAllWalletChainsSelected(true)
    setFilter({ ...filter, blockchainIds: [] })
  }

  const handleGroupChainfilter = (chainIdSelected: string) => {
    if (groupChainsFilter.includes(chainIdSelected)) {
      setGroupChainsFilter(groupChainsFilter.filter((chain) => chain !== chainIdSelected))
      if (groupChainsFilter.filter((chain) => chain !== chainIdSelected).length === 0) {
        setAreAllGroupChainsSelected(true)
      }
    } else {
      setGroupChainsFilter([...groupChainsFilter, chainIdSelected])
      setAreAllGroupChainsSelected(false)
    }
  }
  const handleAllGroupChainSelect = () => {
    setAreAllGroupChainsSelected(true)
    setGroupChainsFilter([])
  }

  const funds: IScoreRatingProps[] = useMemo(() => {
    const list: IScoreRatingProps[] = []
    if (sources && walletBalances && sources?.items?.length > 0) {
      sources?.items?.forEach((source) => {
        const sourceBalance = walletBalances.groups[source.id]?.value || '0'

        const walletCryptoCurrencies = source.ownedCryptocurrencies
        const filteredCryptocurrencies = []
        Object.keys(walletCryptoCurrencies)
          .filter((chain) => (filter.blockchainIds.length > 0 ? filter.blockchainIds.includes(chain) : true))
          .forEach((chain) => {
            filteredCryptocurrencies.push(walletCryptoCurrencies[chain])
          })
        const formattedCryptoCurrencies = filteredCryptocurrencies.flat().map((cryptocurrency) => ({
          publicId: cryptocurrency.publicId,
          imageUrl: cryptocurrency.image.small || '',
          symbol: cryptocurrency.symbol
        }))

        const subTitle = (source.balance && formattedCryptoCurrencies && formattedCryptoCurrencies.length) || 0
        const blockchains = source.supportedBlockchains
        const chains = supportedChains.filter(
          (item) => blockchains?.findIndex((chain) => chain.toLowerCase() === item.id.toLowerCase()) > -1
        )

        list.push({
          id: source.id,
          fiatCurrency: fiatCurrencySetting?.code,
          total: sourceBalance,
          disabled: isWalletSyncing,
          type:
            (source.sourceType === SourceType.GNOSIS && 'Safe') ||
            (source.sourceType === SourceType.ETH && 'EOA Wallet'),
          price: formatNumberWithCommasBasedOnLocale(String(sourceBalance), country?.iso),
          balance: sourceBalance,
          subTitle: `${subTitle} ${subTitle === 1 ? 'Asset' : 'Assets'}`,
          title: source.name || source.address,
          iconRight: deleteSvg,
          onButtonClick: (e) => {
            e.stopPropagation()
            handleShowDeleteModal(source.id)
          },
          iconEdit: editSvg,
          supportedChains,
          onEditButton: (e) => {
            e.stopPropagation()
            if (source.sourceType === SourceType.GNOSIS) {
              router.push(`/${organizationId}/wallets/${source.id}/edit/safe`)
            } else {
              router.push(`/${organizationId}/wallets/${source.id}/edit/eoa`)
            }
          },
          address: source && source.address,
          iconFlag: flagSvg,
          flag: !!source.flaggedAt,
          lastUpdate: source.updatedAt,
          assets: uniqBy(formattedCryptoCurrencies, 'publicId'),
          group: source.group,
          chains
        })
      })
    }

    return list
  }, [sources, walletBalances, isWalletSyncing])

  const wallets: IScoreRatingProps[] = useMemo(
    () => (funds && funds.sort((a, b) => (b.balance > a.balance ? (direction ? 1 : -1) : direction ? -1 : 1))) || [],
    [direction, funds]
  )

  useEffect(() => {
    if (wallets?.length && isParsing) {
      setIsParsing(false)
    }
  }, [wallets])

  const isExisted = sources?.items?.length > 0 || search || Object.values(filter).some((value) => value.length > 0)
  const loading = groupsUninitialized || groupsUninitialized || groupsLoading || walletsLoading

  return (
    <>
      <FormProvider {...methods}>
        <Header className="md:px-3 md:py-4 md:h-fit">
          <Header.Left className="md:h-fit">
            <Header.Left.Title>Wallets</Header.Left.Title>
            {sources && sources?.items?.length > 0 && (
              <div className="pl-4">
                <SyncChip
                  disabled={sources?.items?.length === 0}
                  onClick={startWalletSync}
                  isSyncing={isWalletSyncing}
                  lastUpdated={lastUpdated}
                />
              </div>
            )}
          </Header.Left>

          {isExisted && (
            <div className="flex items-center lg:hidden">
              <div className="flex items-center" data-tip="add_source_of_funds" data-for="add_source_of_funds">
                {/* TODO tech debt: Create two buttons and toggle based on tab, instead of a single button */}
                <Button
                  variant="black"
                  type="button"
                  disabled={isWalletSyncing}
                  height={40}
                  onClick={() => {
                    if (activeTab === EWalletTab.WALLETS) router.push(`/${organizationId}/wallets/import`)
                    else {
                      setAction('Create')
                      setShowCreateGroup(!showCreateGroup)
                    }
                  }}
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                  label={activeTab === EWalletTab.WALLETS ? 'Import Wallet' : 'Create Wallet Group'}
                />
                {isWalletSyncing && (
                  <ReactTooltip
                    id="add_source_of_funds"
                    borderColor="#eaeaec"
                    border
                    backgroundColor="white"
                    textColor="#111111"
                    effect="solid"
                    place="top"
                    className="!opacity-100 !rounded-lg"
                  >
                    <Typography classNames="max-w-[250px]" variant="caption" color="black">
                      We are syncing transactions data. You will be able to add a wallet after the sync is completed.
                    </Typography>
                  </ReactTooltip>
                )}
              </div>
            </div>
          )}
        </Header>
        <View.Content>
          {loading ? (
            <Loading dark height="h-[80%]" title="Fetching Data" />
          ) : isExisted ? (
            <MantineTabs tabs={countedWalletTabs} value={activeTab} onChange={handleChangeTab}>
              <TabsPanel value={EWalletTab.WALLETS}>
                <WalletList
                  data={wallets || []}
                  groups={groups}
                  loading={loading || isParsing}
                  pagination={pagination}
                  setPagination={setPagination}
                  filter={filter}
                  setFilter={setFilter}
                  totalItems={Number(sources?.totalItems)}
                  supportedChains={supportedChains}
                  onChainFilter={handleWalletChainFilter}
                  onSelectAllChains={handleAllWalletChainSelect}
                  areAllChainsSelected={areAllWalletChainsSelected}
                  search={textSearch}
                  onChangeSearch={handleChangeSearch}
                />
              </TabsPanel>
              <TabsPanel value={EWalletTab.GROUPS}>
                <WalletGroup
                  loading={loading}
                  data={parsedWalletGroup}
                  supportedChains={supportedChains}
                  areAllChainsSelected={areAllGroupChainsSelected}
                  onChangeFilter={handleGroupChainfilter}
                  onSelectAllChain={handleAllGroupChainSelect}
                  groupChainsFilter={groupChainsFilter}
                  search={textGroupSearch}
                  onChangeSearch={handleChangeGroupSearch}
                />
              </TabsPanel>
            </MantineTabs>
          ) : (
            <SelectWalletType />
          )}

          <CreateGroupModal
            groups={groups}
            setShowModal={setShowCreateGroup}
            showModal={showCreateGroup}
            action={action}
          />
        </View.Content>
      </FormProvider>
      <LazyQRModal />
    </>
  )
}

export default SourceOfFunds
