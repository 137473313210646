/* eslint-disable react/no-unstable-nested-components */
import { EmptyData } from '@/components-v2/molecules/EmptyData'
import { MantineTable } from '@/components-v2/molecules/MantineTable'
import MoneyIcon from '@/public/svg/MoneyCircle.svg'
import { memberProfileSelector } from '@/slice/organization-members/organization-membes.selectors'
import { orgSettingsSelector } from '@/slice/orgSettings/orgSettings-slice'
import { showBannerSelector } from '@/slice/platform/platform-slice'
import { useAppSelector } from '@/state'
import { useOrganizationId } from '@/utils/getOrganizationId'
import { MRT_ColumnDef as ColumnDef } from 'mantine-react-table'
import { useRouter } from 'next/router'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import ActionCell from './ActionCell'
import AssetCell from './AssetCell'
import BalanceCell from './BalanceCell'
import ChainsCell from './ChainsCell'
import GroupCell, { GroupHeader } from './GroupCell'
import NameCell from './NameCell'

interface WalletTableProps {
  data: any[]
  loading: boolean
  chainsFilter: string[]
  groupOptions: any[]
  totalItems: number
  pagination: {
    pageIndex: number
    pageSize: number
  }
  setPagination: Dispatch<
    SetStateAction<{
      pageIndex: number
      pageSize: number
    }>
  >
  onChangeGroup: (row, groupId: string) => void
}

const WalletTable: React.FC<WalletTableProps> = ({
  data = [],
  loading,
  chainsFilter,
  totalItems = 0,
  groupOptions,
  pagination,
  setPagination,
  onChangeGroup
}) => {
  const router = useRouter()
  const organizationId = useOrganizationId()

  const isWalletSyncing = useAppSelector((state) => state.wallets.isSyncing)
  const isOwner = useAppSelector(memberProfileSelector)?.role === 'Owner'

  const showBanner = useAppSelector(showBannerSelector)
  const { fiatCurrency } = useAppSelector(orgSettingsSelector)

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Name',
        maxSize: 200,
        mantineTableBodyCellProps: {
          classNames: { td: '!overflow-visible lg:!px-2' }
        },
        Cell: ({ row }) => <NameCell data={row.original} isWalletSyncing={isWalletSyncing} />
      },
      {
        accessorKey: 'price',
        header: 'Balance',
        mantineTableBodyCellProps: {
          className: 'lg:!justify-end lg:!px-2'
        },
        Cell: ({ row }) => (
          <BalanceCell data={row.original} isWalletSyncing={isWalletSyncing} fiatCurrency={fiatCurrency} />
        )
      },
      {
        accessorKey: 'chain',
        header: 'Chain/Type',
        mantineTableBodyCellProps: {
          className: 'lg:!hidden'
        },
        maxSize: 100,
        Cell: ({ row }) => (
          <ChainsCell data={row.original} isWalletSyncing={isWalletSyncing} chainsFilter={chainsFilter} />
        )
      },
      {
        accessorKey: 'asset',
        header: '# Assets',
        mantineTableBodyCellProps: {
          className: 'lg:!hidden'
        },
        maxSize: 120,
        Cell: ({ row }) => <AssetCell data={row.original} isWalletSyncing={isWalletSyncing} />
      },
      {
        accessorKey: 'walletGroup',
        header: 'Wallet Group',
        mantineTableBodyCellProps: {
          className: 'lg:!hidden'
        },
        Header: () => <GroupHeader />,
        Cell: ({ row }) => <GroupCell data={row.original} groupOptions={groupOptions} onChangeGroup={onChangeGroup} />
      },
      {
        id: 'action',
        header: '',
        size: 10,
        maxSize: 10,
        mantineTableBodyCellProps: {
          pl: 8,
          className: 'lg:!hidden'
        },
        Cell: ({ row }) => <ActionCell data={row.original} isWalletSyncing={isWalletSyncing} isOwner={isOwner} />
      }
    ],
    [groupOptions, isWalletSyncing, chainsFilter, isOwner]
  )

  return (
    <MantineTable
      data={data}
      columns={columns}
      layoutMode={data?.length > 0 ? 'grid' : 'semantic'}
      onClickRow={(row) => {
        router.push(`/${organizationId}/wallets/${row?.id}`)
      }}
      rowCount={totalItems}
      state={{ pagination, isLoading: loading }}
      onPaginationChange={setPagination}
      manualPagination
      mantinePaperProps={{
        mt: { lg: 16 },
        className: 'lg:!border-none'
      }}
      mantineTableHeadProps={{
        className: 'lg:!hidden'
      }}
      mantineTableContainerProps={{
        className: `${showBanner ? 'h-[calc(100vh-480px)]' : 'h-[calc(100vh-410px)]'} lg:h-auto overflow-auto`
      }}
      renderEmptyRowsFallback={(props) => (
        <EmptyData>
          <EmptyData.Icon icon={MoneyIcon} />
          <EmptyData.Title>No wallets found</EmptyData.Title>
        </EmptyData>
      )}
    />
  )
}

export default WalletTable
